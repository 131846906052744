import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export default function About({ data, location }) {
	return (
		<Layout location={location} lang="en">
			<Seo title="About" />
			<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
				<div className="flex flex-col-reverse flex-col items-center justify-between lg:flex-row">
					<div className="relative lg:w-1/2">
						<GatsbyImage
							className="object-cover w-full shadow-lg"
							image={
								data.allMarkdownRemark.nodes[0].frontmatter.aboutImage
									.childImageSharp.gatsbyImageData
							}
							alt="About Art"
						/>
					</div>
					<div className="mb-10 lg:max-w-lg lg:mb-0">
						<div className="max-w-xl mb-6">
							<h2 className="max-w-lg mb-6 text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
								About Atelier Motif
							</h2>
							<p className="text-base text-gray-700 dark:text-white md:text-lg">
								Atelier Motif is a Plovdiv-based interior design, architecture
								and art studio. Every project has a motif, a main idea and for
								us that is to find the balance between your dreams and needs and
								what is functional and aesthetically pleasing, using the right
								materials, beautiful art and decoration. No project is too big
								or small, our specialty is in private homes, but if you have an
								office space, restaurant or hotel that needs designing we are
								here to help. We don’t limit ourselves in just one aspect of the
								design field and we can guide you through the whole process from
								project design to realization.
							</p>
							<h3 className="mb-3 pt-1 text-base text-gray-700 dark:text-white md:text-xl">
								Our services include:
							</h3>
							<ul className="-ml-1 space-y-2 text-base text-gray-700 dark:text-white">
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Interior design
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Architecture
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Art
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Furniture design and fabrication
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Project realization
								</li>
							</ul>
							<h3 className="mb-3 pt-1 text-base text-gray-700 dark:text-white md:text-xl">
								Let’s get started.
							</h3>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const aboutQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { frontmatter: { aboutImage: { id: { ne: null } } } }
		) {
			nodes {
				frontmatter {
					aboutImage {
						childImageSharp {
							gatsbyImageData(formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			}
		}
	}
`;
